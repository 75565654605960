import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { Link, navigate } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import chevron from "../../images/svg/chevron_secondary.svg";
import chevronWhite from "../../images/svg/chevron_left.svg";

const CtaSecondary = (props) => {
  const {
    ctaTitle,
    target,
    className,
    reverseIcon,
    openInNewTab,
    withWhiteChevron,
    style,
    isSliderAnchor,
    clickHandler,
    dataPass,
    download,
    textWhite,
  } = props;
  const handleClick = (targetURL) => navigate(targetURL);
  if (isSliderAnchor)
    return (
      <AnchorLink
        className={`cta-secondary ${className} ${
          withWhiteChevron ? "white-border" : ""
        }`}
        to={target}
        style={style}
      >
        <span className="text-black ">{ctaTitle}</span>
        {!reverseIcon && (
          <img
            src={withWhiteChevron ? chevronWhite : chevron}
            alt="chevron icon"
            style={{ margin: "2px 5px 0px" }}
          />
        )}
      </AnchorLink>
    );
  if (clickHandler)
    return (
      <a
        onClick={() => clickHandler(dataPass)}
        href="#"
        className={`cta-secondary ${className} ${
          withWhiteChevron ? "white-border" : ""
        }`}
      >
        <span className="text-black ">{ctaTitle}</span>
        {!reverseIcon && (
          <img
            src={withWhiteChevron ? chevronWhite : chevron}
            alt="chevron icon"
            style={{ margin: "2px 5px 0px" }}
          />
        )}
      </a>
    );
  if (download)
    return (
      <a
        download={download}
        href={target}
        className={`cta-secondary ${className} ${
          withWhiteChevron ? "white-border" : ""
        }`}
      >
        <span className={textWhite ? "text-white" : "text-black"}>
          {ctaTitle}
        </span>
        {!reverseIcon && (
          <img
            src={withWhiteChevron ? chevronWhite : chevron}
            alt="chevron icon"
            style={{ margin: "2px 5px 0px" }}
          />
        )}
      </a>
    );
  return (
    <Button
      className={`cta-secondary ${className} ${
        withWhiteChevron ? "white-border" : ""
      }`}
      type="secondary"
      href={target}
      download={download || null}
      target={openInNewTab ? "_blank" : "_self"}
      rel={openInNewTab && target ? "noreferrer" : ""}
      onClick={() => !openInNewTab && handleClick(target)}
      style={style}
    >
      {reverseIcon && (
        <img
          src={withWhiteChevron ? chevronWhite : chevron}
          alt="chevron icon"
          style={{
            margin: "5px 24px 2px 10px",
            transform: "rotate(180deg)",
          }}
        />
      )}
      {ctaTitle}
      {!reverseIcon && (
        <img
          src={withWhiteChevron ? chevronWhite : chevron}
          alt="chevron icon"
          style={{ margin: "2px 5px 0px" }}
        />
      )}
    </Button>
  );
};

CtaSecondary.propTypes = {
  className: PropTypes.string,
  ctaTitle: PropTypes.string,
  target: PropTypes.string,
  reverseIcon: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  withWhiteChevron: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  isSliderAnchor: PropTypes.bool,
  clickHandler: PropTypes.func,
  dataPass: PropTypes.string,
  download: PropTypes.string,
  textWhite: PropTypes.bool,
};
CtaSecondary.defaultProps = {
  className: "",
  ctaTitle: "Primary CTA",
  target: "/",
  reverseIcon: false,
  openInNewTab: false,
  withWhiteChevron: false,
  isSliderAnchor: false,
  style: {},
  clickHandler: null,
  dataPass: null,
  download: "",
  textWhite: false,
};

export default CtaSecondary;
